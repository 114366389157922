<template>
  <v-card>
    <v-card-text class="">
      <v-img v-if="fotoAbogado!=null" :src="fotoAbogado" aspect-ratio="1.3" contain />
      <v-img v-if="fotoAbogado!=null" :src="firmaAbogado" aspect-ratio="2.8" contain/>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('abogado',{fotoAbogado:'getFotoAbogado',firmaAbogado:'getFirmaAbogado'})
  }
};
</script>